<template>
  <div class="v-select-infinite">
  <v-select
    v-if="this.min_search"
    :options="paginated"
    :filterable="false"
    :placeholder="placeholder"
    @open="onOpen"
    @close="onClose"
    @search="(query) => { search = query; $emit('keyup', query); }"
    v-model="value"
    @input="$emit('input', value)"
    v-on:change="$emit('change')"
    :multiple="multiple"
    :dropdown-should-open="dropdownShouldOpen"
  >
    <template #list-footer>
      <li v-show="hasNextPage" ref="load" class="loader">
      </li>
    </template>
    <span slot="no-options" @click="$refs.select.open = false">
       {{empty_label}}
    </span>
  </v-select>

  <v-select
    v-if="!this.min_search"
    :options="paginated"
    :filterable="false"
    :placeholder="placeholder"
    @open="onOpen"
    @close="onClose"
    @search="(query) => { search = query; $emit('keyup', query); }"
    v-model="value"
    @input="$emit('input', value)"
    v-on:change="$emit('change')"
    :multiple="multiple"
  >
    <template #list-footer>
      <li v-show="hasNextPage" ref="load" class="loader">
        Loading more options...
      </li>
    </template>
    <span slot="no-options" @click="$refs.select.open = false">
       {{empty_label}}
    </span>
  </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
  name: 'VSelectInfinite',
  components: {
    vSelect
  },
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    placeholder: {
        type: String,
        default() {
            return "Buscar"
        }
    },
    empty_label: {
        type: String,
        default() {
            return "Nenhuma opção encontrada."
        }
    },
    multiple: {
      type: Boolean,
      default() {
        return false
      }
    },
    min_search: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data: () => ({
    observer: null,
    limit: 50,
    search: '',
    value: null
  }),
  computed: {
    filtered() {
      return this.search.length ? this.options.filter((opt) => {
            try {opt = opt.toString()} catch (error) {}
            return opt.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').toLowerCase().indexOf(
                this.search.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '').toLowerCase()
            ) != -1
        } ) : this.options
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length
    },
  },
  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },

    dropdownShouldOpen(VueSelect) {
      if (this.value !== null && this.value.length > 3) {
        return VueSelect.open
      }
      return VueSelect.search.length > 0 && VueSelect.open
    },

    defaultValue(value) {
      this.value = value
    }
  },
}
</script>