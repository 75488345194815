<template>
  <div class="page-principal-faq">
        <div class="container">
            <h1>{{__('Dúvidas que talvez você tenha')}}</h1>

            <div class="row">
                <div class="col-sm-12 col-md-8">

                    <div class="faq-list">
                        <div 
                            v-for="faq,idx in faq_list" 
                            v-bind:key="`item_${idx}`"
                            @click="toogle_faq(idx)" 
                            class="faq-item"
                            :class="opened_faq == idx ? 'open':''"
                        >
                            <div v-html="__(faq.title)" class="faq-item-header"></div>
                            <div v-html="__(faq.description)" class="faq-item-description"></div>
                        </div>
                    </div>

                </div>
                <div class="col-sm-12 col-md-4">
                    <ContactForm/>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
import Vue from "vue";
import "@/assets/scss/custom/principal/_faq.scss"
import ContactForm from '../components/apolar/principal/contact-form.vue';

export default {
  name: "faq",
  components: {
    ContactForm
  },
  metaInfo() {
    return {
      title: this.__("FAQ | Imobiliária em Curitiba - Imóveis à Venda e Locação"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content:"Consulte as dúvidas frequentes ou nos envie também a suas. Acesse agora mesmo e esclareça suas dúvidas!"
        }
      ]
    };
  },
  data() {
    return {
        opened_faq: null,
        faq_list: [
            {
                "title": "Tenho um imóvel para vender ou alugar. O que devo fazer?",
                "description": "Basta clicar em <a targer='_blank' href='https://www.apolar.com.br/anuncie/'>Anuncie</a> ou entrar em contato com uma das nossas lojas. Nossos corretores estão preparados para fazer a melhor avaliação do mercado, garantindo agilidade no negócio."
            },
            {
                "title": "Por que devo escolher a Apolar para administrar o meu imóvel?",
                "description": "A Apolar tem mais de 50 anos de tradição no mercado imobiliário e se destaca pela força de divulgação, com mais de 100 lojas interligadas, que trabalham em rede, e equipe de especialistas na oferta do imóvel. Além de parceria com os principais portais imobiliários, onde seu imóvel será anunciado, divulgação nas redes sociais e no próprio portal Apolar, que tem mais de 200 mil acessos/mês. A Apolar também trabalha com uma equipe jurídica própria, com advogados especialistas na área imobiliária, que fornecem toda assessoria necessária para os clientes."
            },
            {
                "title": "Estou procurando um imóvel para alugar ou comprar, com quem devo falar?",
                "description": "O portal da Apolar é bem completo e atualizado. Com a ajuda dos filtros avançados de busca, você pode navegar e buscar opções de acordo com a sua necessidade. Também temos o serviço de chat online, que oferece agilidade no atendimento. Se quiser nos visitar, será um prazer recebê-lo em uma das nossas unidades. Encontre a mais próxima de você."
            },
            {
                "title": "Que tipo de documentação preciso para alugar/comprar ou vender um imóvel?",
                "description": "Para alugar basta apresentar seu RG e CPF para que sua análise cadastral possa ser realizada.<br>\
    Para vender é necessário o documento do imóvel, seu RG e CPF e comprovante do estado civil.<br>\
    Para comprar o imóvel é necessário apresentar RG e CPF, comprovante do estado civil, além de certidões pessoais em caso de financiamento.<br>\
    *Outros documentos podem ser necessários na dependência de situações especiais. Nesses casos, o corretor/analista fornecerá as orientações pertinentes."
            },
            {
                "title": "Sou inquilino (a) da Apolar e estou com um problema no imóvel. A quem devo procurar?",
                "description": "A Apolar oferece assistência técnica 24h, de forma gratuita. Basta ligar para o telefone 0800 707 79 56 e agendar uma visita."
            }
        ]
    };
  },
  computed: {


  },
  methods: {
    toogle_faq(faq_idx) {
        this.opened_faq = this.opened_faq == faq_idx ? null : faq_idx
    }
  },
  mounted() {
    if(this.lang == 'es'){
      this.faq_list.pop()
    }
  }
};
</script>

<style scoped lang="scss">
  @import "~@/assets/scss/custom/principal/_faq.scss"
</style>