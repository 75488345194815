<template>
    <div class="form-block" v-if="lang == 'es'">
        <h2 class="form-block-title">{{ __("Cadastre-se e receba um e-book sobre o nosso modelo de franquia") }}</h2>
        <h4 class="form-block-subtitle">{{ __("Um consultor entrará em contato com você") }}</h4>
        <form 
            ref="franchiseeForm"
            method="POST"
            @submit.prevent="submitForm"
        >
            
            <div class="row">
                <transition name="fade" mode="out-in">
                    <div v-if="send_status === 'sending'" key="sending" class="send-proccess">
                        <bounce-loader :color="'#FFC20F'"></bounce-loader>
                        <h6>{{ __('Carregando.') }}</h6>
                    </div>
                    <div v-else-if="send_status === 'sended'" key="sended" class="send-proccess">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        <h6>{{ __('Contato enviado!') }}</h6>
                    </div>
                    <div v-else-if="send_status === 'error'" key="error" class="send-proccess">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        <h6>{{ __('Error!') }}</h6>
                        <h6>{{ __('Tente novamente mais tarde!') }}</h6>
                    </div>
                </transition>
                <div class="col-md-6">
                    <label class="form-block-label" for="first_name">
                        <span>{{ __("Nome") }}</span>
                        <input class="form-block-input primaryInput firstStepInput" id="first_name" maxlength="40" name="first_name" size="20" type="text" required placeholder="Escreva seu nome" v-model="FormData['first_name']"/>
                        <!--<span class="primaryInput-span">Insira seu nome</span>-->
                    </label>
                </div>
                <div class="col-md-6">
                    <label for="email" class="form-block-label">
                        <span style="white-space: nowrap;">{{ __("Sobrenome") }}</span>
                        <input class="form-block-input primaryInput firstStepInput" id="last_name" maxlength="80" name="last_name" size="20" type="text" required placeholder="Escreva seu sobrenome" v-model="FormData['last_name']"/>
                        <!--<span class="primaryInput-span">Insira um email válido</span>-->
                    </label>
                </div>
                <div class="col-md-6">
                    <label for="email" class="form-block-label">
                        <span>{{ __("E-mail") }}</span>
                        <input class="form-block-input primaryInput firstStepInput" id="email" maxlength="80" name="email" size="20" type="email" required pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$" placeholder="Digite seu E-mail" v-model="FormData['email']"/>
                        <!--<span class="primaryInput-span">Insira um email válido</span>-->
                    </label>
                </div>
                <div class="col-md-6">
                    <label for="phone" class="form-block-label">
                        <span>{{ __("Telefone") }}</span>
                        <input class="form-block-input primaryInput firstStepInput" v-mask="phone_mask" type="phone" id="00NHu00001DRzwH" name="00NHu00001DRzwH" placeholder="Digite seu telefone" v-model="FormData['phone']">
                        <!--<span class="primaryInput-span">Insira seu telefone</span>-->
                    </label>
                </div>

                <!-- Estado -->
                <div class="col-md-6">
                    <label class="form-block-label">
                        <span>{{ __("Estado") }}</span>
                        <input
                            v-model="selectedState"
                            type="text"
                            class="form-block-input firstStepInput"
                            placeholder="Digite um estado"
                            @focus="showStateList = true"
                            @blur="hideListWithDelay('state')"
                            @input="filterStates"
                            ref="stateInput"
                        />
                        <div v-show="showStateList" class="first-step-options-list show" ref="stateList">
                            <ul>
                            <li v-for="state in filteredStates" :key="state">
                                <button type="button" @mousedown="selectState(state)">{{ state }}</button>
                            </li>
                            </ul>
                        </div>
                    </label>
                </div>
                <!-- Cidade -->
                <div class="col-md-6">
                    <label class="form-block-label">
                        <span>{{ __("Cidade") }}</span>
                        <input
                            v-model="selectedCity"
                            type="text"
                            class="form-block-input firstStepInput"
                            placeholder="Digite uma cidade"
                            :disabled="!selectedState"
                            @focus="showCityList = true"
                            @blur="hideListWithDelay('city')"
                            @input="filterCities"
                            ref="cityInput"
                        />
                        <div v-show="showCityList" class="first-step-options-list show" ref="cityList">
                            <ul>
                            <li v-for="city in filteredCities" :key="city">
                                <button type="button" @mousedown="selectCity(city)">{{ city }}</button>
                            </li>
                            </ul>
                        </div>
                    </label>
                </div>
                <div class="col-xl-12">
                    <button ref="submitButtonRef"
                            :disabled="submitButtonDisabled"
                            :class="{ disabled: submitButtonDisabled }"
                            type="button"
                            class="btn_default disabled"
                            @input="validateForm()"
                            @mouseover="validateForm()"
                            @click="submitForm()"
                            :data-empty-fields="__('Preencha todos os campos.')">
                            <span ref="buttonText" class="button-text">{{ __("Enviar") }}</span>
                            <span ref="loader" class="button-loader" style="display: none;">⏳</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import "@/assets/scss/custom/principal/components/_franchisee_register_section.scss"
import {mask} from 'vue-the-mask'
import ContactService from "../../../../services/ContactService";
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'

export default {
    directives: {mask},
    components: {
        BounceLoader
    },
    props: {
    },
    computed: {
        phone_mask() {
            let mask = ['(##) #####-####']
            if (this.lang == 'es') {
                mask = ['##############']
            }
            return mask
        }
    },
    data() {
        return {
            counterHistoryYears: 0,
            counterGeneralSellers: 0,
            counterMonthlyAccesses: 0,
            counterPropertiesAvailable: 0,
            FormData: {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                state: "",
                city: ""
            },
            submitButtonDisabled: true,
            selectedState: "",
            selectedCity: "",
            countries: ["Paraguai"],
            states: [],
            cities: [],
            filteredStates: [],
            filteredCities: [],
            showStateList: false,
            showCityList: false,
            send_status: null
        };
    },
    methods: {
        animateCounter(counterName, targetValue, duration) {
        const startValue = this[counterName];
        let startTime = null;

        const animate = (timestamp) => {
            if (!startTime) startTime = timestamp;

            const progress = timestamp - startTime;
            const step = Math.min(progress / duration, 1);
            this[counterName] = Math.floor(startValue + step * (targetValue - startValue));

            if (progress < duration) {
            requestAnimationFrame(animate);
            } else {
            this[counterName] = targetValue;
            }
        };

        requestAnimationFrame(animate);
        },
        validateForm(){
            let allValid = true;

            Object.keys(this.FormData).forEach(key => {
            const value = this.FormData[key];
            
            if (!value || value.trim() === "") {
                allValid = false;
            }

            if (key === "email") {
                const emailPattern = new RegExp(
                "^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$"
                );
                if (!emailPattern.test(value.trim())) {
                allValid = false;
                }
            }
            });

            if (allValid) {
                this.submitButtonDisabled = false;
                this.$nextTick(() => {
                    const button = this.$refs.franchiseeSubNextStep;
                    if (button) {
                        if (button.classList.contains('disabled')) {
                            button.classList.remove('disabled');
                        }
                        button.removeAttribute('disabled');
                    }
                });
            } else {
                this.submitButtonDisabled = true;
            }


        },

        async submitForm() {
            const button = this.$refs.submitButtonRef;
            const loader = this.$refs.loader;
            const buttonText = this.$refs.buttonText;
            const form = this.$refs.franchiseeForm;
            this.send_status = 'sending';

            button.disabled = true;
            loader.style.display = "inline-block";
            buttonText.style.display = "none";

            let send_to = this.lang == 'ptBR' ? 'apolar@apolar.com.br' : 'joao.geron@apolar.com.py'
            
            let data_contact = {
                "subject": `Contato do Site Fale Conosco - Enviado por ${this.FormData.first_name}`,
                "to": send_to,
                "fields": [
                    { "label": "Nome Completo", "value": this.FormData.first_name + ' ' + this.FormData.last_name },
                    { "label": "Telefone", "value": this.FormData.phone },
                    { "label": "E-mail", "value": this.FormData.email },
                    { "label": "Estado", "value": this.selectedState },
                    { "label": "Cidade", "value": this.selectedCity }
                ],
                "_recaptcha_token": await this.createRecaptchaToken('CONTACT')
            };

            ContactService.sendGenericContact(data_contact)
            .then((response) => {
                this.send_status = 'sended';
                this.FormData = {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: ''
                };
                this.selectedState = '';
                this.selectedCity = '';
            })
            .catch(error => {
                console.error("Erro:", error);
                this.send_status = 'error'
            })
            .finally(() => {
                setTimeout(() => {
                    button.disabled = false;
                    loader.style.display = "none";
                    buttonText.style.display = "block";

                    setTimeout(() => {
                        this.send_status = null;
                    }, 2000);
                }, 2000);
            });

        },
        async loadStates() {
            try {
                const response = await fetch("/json/py-cities.json");
                if (!response.ok) throw new Error("Erro ao carregar JSON");

                const data = await response.json();
                this.states = data.map((state) => state.nome);
                this.filteredStates = [...this.states];
            } catch (error) {
                console.error("Erro ao carregar estados:", error);
            }
        },
        hideListWithDelay(type) {
            setTimeout(() => {
            if (type === "state") this.showStateList = false;
            if (type === "city") this.showCityList = false;
            }, 200);
        },
        filterStates() {
            const search = this.selectedState.toLowerCase();
            this.filteredStates = this.states.filter((state) =>
                state.toLowerCase().includes(search)
            );
        },
        selectState(state) {
            this.selectedState = state;
            this.FormData.state = state;
            this.selectedCity = "";
            this.FormData.city = "";
            this.showStateList = false;
            this.loadCities(state);
        },
        loadCities(stateName) {
            const url = "./json/py-cities.json";

            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                const selectedState = data.find((state) => state.nome === stateName);
                this.cities = selectedState ? selectedState.cidades : [];
                this.filteredCities = [...this.cities];
                })
                .catch((error) => console.error("Erro ao carregar cidades:", error));
        },
        filterCities() {
            const search = this.selectedCity.toLowerCase();
            this.filteredCities = this.cities.filter((city) =>
                city.toLowerCase().includes(search)
            );
        },
        selectCity(city) {
            this.selectedCity = city;
            this.FormData.city = city;
            this.showCityList = false;
        },
    },
    mounted() {
        this.animateCounter('counterHistoryYears', 50, 4000);
        this.animateCounter('counterGeneralSellers', 3, 4000);
        this.animateCounter('counterMonthlyAccesses', 1, 3000);
        this.animateCounter('counterPropertiesAvailable', 5, 3000);
        this.loadStates();
        this.loadRecaptchaTag()
    },
    watch:{
        selectedState(newVal) {
            if (!newVal) {
                this.selectedCity = "";
            }
        },
    },
    beforeDestroy() {
    }
};
</script>