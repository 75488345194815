<template lang="">
    <div class="indique-perguntas-main">
    <div class="indique-perguntas-background">
        <div class="question-task-image"></div>
    </div>
    <div class="row indique-perguntas-box d-flex justify-content-between pt-4">
        <div class="dropdown-question">
            <h1>PERGUNTAS FREQUENTES</h1>
            <h5>As perguntas mais feitas por quem usa o indica</h5>
            <template v-for="tfilter,key in flag_filters">
                <div class="questions-options">
                    <div v-bind:key="`tag_${key}`" class="tag-filter-indique">
                        <div
                            class="tag-filter-header"
                            v-on:click.stop="toogleTagFilter"
                        >
                            <span 
                            >{{tfilter.label}}</span
                            >
                            <div class="dropdown-icon">
                            </div>
                        </div>
                    </div>
                    <div class="tag-filter-options" style="display: none">
                        <div class="tag-filter-option">
                        <span v-html="tfilter.text">
                        </span>
                            
                        </div>
                    </div>
                </div>
            </template>
        </div> 
        <div class="form-question">
            <QuestionForm/>
        </div>
    </div>
</div>
    
</template>
<script>
import Vue from "vue";
import jQuery from "jquery";
import _ from 'lodash';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { VMoney } from 'v-money'
import VSelectInfinite from "../VSelectInfinite.vue"
import VSelectMultipleVue from '../VSelectMultiple.vue';
import checkNumbersVue from '../check-numbers.vue';

import PropertiesService from "../../../../services/PropertiesService";
import QuestionForm from './question-form-indique.vue';

export default {
    directives: { money: VMoney },
    components: {
        vSelect,
        VSelectInfinite,
        VSelectMultipleVue,
        checkNumbersVue,
        QuestionForm
    },
    props: {
    },
    data() {

        var _filters = {
            business: "Vendas", //ok
            business_subfilter: "Residencial", //ok
            reference: '', //ok
            city: null, //ok
            district: [], //ok
            property_type: [], //ok
            property_type_combo: [], // ok
            bedrooms: [], //ok
            garage: [], //ok
            bathrooms: [],//ok
            price_max: null, //ok
            price_min: null, //ok
            area_max: null,//ok
            area_min: null,//ok
            address: null, //ok
            address_number: null,
            open_search: '',//ok
            in_condominium: false, // ok,
            include_condominium_price: false,
            conveniences: [],
            recreation: [],
            facilities: [],
            rooms: [],
        }

        Object.keys(this.$advanced_filters).forEach((key) => {
            _filters[key] = this.$advanced_filters[key]
        })

        var _pre_purpose = 'tolive'

        if (this.$advanced_filters['business_subfilter'] == 'Comercial') {
            _pre_purpose = 'commercial'
        }

        return {
            filters: _filters,
            show_applied_filters: false,
            pre_purpose: _pre_purpose,
            flag_filters: [
                {
                   
                    "label": "Posso indicar qualquer imóvel?",
                    "text": `Sim, você pode indicar imóveis residenciais e comerciais. Para premiação 
                    consultar o regulamento.`,
                },
                {
                   
                    "label": "Quais as cidades e bairros válidos na indicação?",
                    "text": `Nós trabalhamos com imóveis de várias cidade do Brasil, consulte o regulamento.`,
                },
                {
                   
                    "label": "Como eu sei que a indicação foi aprovada?",
                    "text": `Quando o imóvel for publicado, você receberá um e-mail informando a aprovação. 
                    Mas você também pode acompanhar pelo campo “minhas indicações” no site da Ops! Ganhei.`,
                },
                {
                   
                    "label": "Qual o regulamento?",
                    "text": `<h3>CONDI&Ccedil;&Otilde;ES E POL&Iacute;TICAS DE PRIVACIDADE DA INDIQUEIAPOLAR LOCA&Ccedil;&Atilde;O E VENDAS</h3>

<p>Este termo constitui um contrato entre o participante e Imobili&aacute;rias Parceiras Apolar, conforme definido abaixo, regendo seu acesso e uso a plataforma IndiqueiApolar, assim como todo e qualquer processamento de pagamento atrav&eacute;s de/ou relacionado &agrave; participa&ccedil;&atilde;o ao programa de indica&ccedil;&atilde;o de Im&oacute;veis. Ao fazer uso da forma de indica&ccedil;&atilde;o de im&oacute;veis atrav&eacute;s da plataforma o participante aceita e se submete &agrave;s condi&ccedil;&otilde;es e pol&iacute;ticas a seguir:</p>

<h4>1. DA CAMPANHA</h4>

<p>1.1 A campanha tem como objetivo permitir aos participantes a indica&ccedil;&atilde;o de im&oacute;veis dispon&iacute;veis no mercado para loca&ccedil;&atilde;o e vendas.</p>

<p>1.2 A indica&ccedil;&atilde;o ser&aacute; atrav&eacute;s da plataforma da IndiqueiApolar.</p>

<p>1.3 O participante poder&aacute; acompanhar o andamento de suas indica&ccedil;&otilde;es e seus ganhos atrav&eacute;s da plataforma.</p>

<p>1.4 &nbsp;As Imobili&aacute;rias Parceiras da IndiqueiApolar oferecem premia&ccedil;&atilde;o atrav&eacute;s de dep&oacute;sito banc&aacute;rio diretamente na conta do participante por indica&ccedil;&otilde;es de im&oacute;veis efetivamente publicados no site da Imobili&aacute;ria ou portais parceiros.</p>

<p>1.5 O valor do pr&ecirc;mio ser&aacute; sempre informado na plataforma, podendo sofrer altera&ccedil;&otilde;es dependendo de campanhas realizadas pelas Imobili&aacute;rias Parceiras da IndiqueiApolar.</p>

<h4>2. DOS PARTICIPANTES</h4>

<p>2.1 Poder&atilde;o participar da campanha:</p>

<p>- Pessoas f&iacute;sicas com CPF,</p>

<p>- Maiores de 18 anos,</p>

<p>- Capacidade para pr&aacute;tica dos atos civis,</p>

<p>- Sem v&iacute;nculo empregat&iacute;cio com as Imobili&aacute;rias Parceiras IndiqueiApolar,</p>

<p>- Corretores aut&ocirc;nomos de vendas regularmente cadastrados com as Imobili&aacute;rias Parceiras da IndiqueiApolar, apenas para indicar im&oacute;veis para loca&ccedil;&atilde;o.</p>

<h4>3. DO CADASTRO</h4>

<p>3.1 O participante dever&aacute; preencher o formul&aacute;rio na plataforma da IndiqueiApolar informando seus dados pessoais e dados banc&aacute;rios. Atrav&eacute;s de seu telefone celular receber&aacute; mensagem da IndiqueiApolar para validar seu cadastro.</p>

<p>3.2 Dever&aacute; informar ainda, o endere&ccedil;o completo do im&oacute;vel a ser indicado, telefone e nome completo do propriet&aacute;rio do im&oacute;vel. Essas informa&ccedil;&otilde;es dever&atilde;o ser v&aacute;lidas, quanto mais informa&ccedil;&otilde;es forem disponibilizadas, maior ser&aacute; a agilidade para a publica&ccedil;&atilde;o do im&oacute;vel no site e portais parceiros, consequentemente, do participante receber os pr&ecirc;mios pela indica&ccedil;&atilde;o.</p>

<p>3.3 &Eacute; de suma import&acirc;ncia que o participante disponibilize informa&ccedil;&otilde;es atuais, precisas e completas durante o processo de inscri&ccedil;&atilde;o na plataforma IndiqueiApolar, assim como mantenha as informa&ccedil;&otilde;es de seu perfil sempre atualizadas, uma vez que, toda e qualquer informa&ccedil;&atilde;o das suas indica&ccedil;&otilde;es e/ou premia&ccedil;&otilde;es dever&aacute; ser acompanhado dentro da plataforma.</p>

<p>3.4 Caso a IndiqueiApolar considere um cadastro ou as informa&ccedil;&otilde;es contidas suspeito de conter dados inver&iacute;dicos ou err&ocirc;neos, reserva-se no direito de suspender o participante do cadastro, assim como impedir ou bloquear seu acesso &agrave; plataforma, cancelando as indica&ccedil;&otilde;es j&aacute; realizadas sem preju&iacute;zo de outras medidas que entenda necess&aacute;rias. No caso de aplica&ccedil;&atilde;o desta san&ccedil;&atilde;o, o participante n&atilde;o ter&aacute; direito a qualquer tipo de indeniza&ccedil;&atilde;o ou ressarcimento por perdas e danos, lucros cessantes ou danos morais.</p>

<p>3.5 O participante se compromete a avisar imediatamente a IndiqueiApolar, por meio dos canais de contato dispon&iacute;veis na plataforma sobre qualquer uso indevido de sua conta.</p>

<p>3.6 A IndiqueiApolar poder&aacute; recusar qualquer solicita&ccedil;&atilde;o de cadastro ou cancelar o cadastro de participantes a seu exclusivo crit&eacute;rio.</p>

<h4>4. DA INDICA&Ccedil;&Atilde;O</h4>

<p>4.1 Ser&atilde;o aceitos indica&ccedil;&atilde;o de qualquer tipo de im&oacute;vel, por&eacute;m s&oacute; receber&atilde;o a premia&ccedil;&atilde;o as seguintes indica&ccedil;&otilde;es:</p>

<p><strong>Loca&ccedil;&atilde;o:</strong></p>

<p>Para cidades de Curitiba, Pinhais, Arauc&aacute;ria, Colombo, S&atilde;o Jos&eacute; dos Pinhais e Campo Largo:</p>

<p>Ser&atilde;o premiadas as indica&ccedil;&otilde;es de im&oacute;veis residenciais, com valor de aluguel acima de R$ 700,00 (setecentos reais) em qualquer bairro.</p>

<p><strong>Vendas:</strong></p>

<p>Para cidades de Curitiba, Arauc&aacute;ria, Balne&aacute;rio Camboriu, Guarapuava e Campo Largo:</p>

<p>Somente im&oacute;veis residenciais, com valor de vendas acima de R$ 120.000,00 (cento e vinte mil reais).</p>

<p>4.2 Im&oacute;veis em outras localidades ou diferenciados tais como, comerciais, pr&eacute;dios ou propriet&aacute;rios com v&aacute;rios im&oacute;veis entre outros, poder&atilde;o ser analisados individualmente pela ger&ecirc;ncia comercial e diretoria, ficando a crit&eacute;rio exclusivo da administradora a participa&ccedil;&atilde;o ou n&atilde;o nesta campanha.</p>

<p>4.3 Caso a aprova&ccedil;&atilde;o e publica&ccedil;&atilde;o no site e portais parceiros ocorra ap&oacute;s 90 (noventa) dias da indica&ccedil;&atilde;o, o participante n&atilde;o far&aacute; jus ao pr&ecirc;mio pela indica&ccedil;&atilde;o.</p>

<p>4.4 A op&ccedil;&atilde;o para venda ou loca&ccedil;&atilde;o assinada pelo propriet&aacute;rio dever&aacute; ser no prazo m&iacute;nimo de 120 dias.</p>

<p>4.5 O im&oacute;vel indicado para venda dever&aacute; estar devidamente averbado, caso n&atilde;o tenha averba&ccedil;&atilde;o o participante n&atilde;o far&aacute; jus a premia&ccedil;&atilde;o.</p>

<p>4.6 O im&oacute;vel ser&aacute; avaliado pelo nosso corretor/ angariador pelo pre&ccedil;o de mercado, caso o propriet&aacute;rio queira colocar um valor superior ao avaliado ficar&aacute; a crit&eacute;rio da administradora aceitar.</p>

<p>4.7 O participante aceita e entende que nem toda indica&ccedil;&atilde;o ser&aacute; premiada, sendo premiadas aquelas que atendem os crit&eacute;rios acima elencados.</p>

<p>4.8 O representante da Imobili&aacute;ria Parceira IndiqueiApolar poder&aacute; realizar visitas t&eacute;cnicas aos im&oacute;veis, avaliando o estado f&iacute;sico do im&oacute;vel, limpeza, se o mesmo est&aacute; vago, ou seja, sem pessoas habitando etc. Reservando o direito da mesma recusar a aprova&ccedil;&atilde;o e publica&ccedil;&atilde;o no site e portais, desta forma o participante n&atilde;o far&aacute; jus ao pr&ecirc;mio pela indica&ccedil;&atilde;o.</p>

<h4>5. DA REMUNERA&Ccedil;&Atilde;O</h4>

<p>5.1 O valor da premia&ccedil;&atilde;o ser&aacute; sempre informado na plataforma da IndiqueiApolar, e poder&aacute; sofrer varia&ccedil;&otilde;es de valores a crit&eacute;rio da Imobili&aacute;ria Parceira. As altera&ccedil;&otilde;es de valores poder&atilde;o ocorrer para todos os im&oacute;veis, regi&otilde;es ou para parte deles dependendo da campanha a ser realizada, n&atilde;o dando direito ao participante solicitar quaisquer diferen&ccedil;as de valores.</p>

<p>5.2 A premia&ccedil;&atilde;o ser&aacute; paga atrav&eacute;s de dep&oacute;sito banc&aacute;rio diretamente na conta do participante que ser&aacute; informado no momento do cadastramento na plataforma.</p>

<p>5.3 Ser&aacute; efetuado o pagamento ao participante em at&eacute; 18 (dezoito) dias do m&ecirc;s subsequente &agrave; publica&ccedil;&atilde;o do im&oacute;vel.</p>

<p>5.4 A IndiqueiApolar n&atilde;o se responsabiliza pelas obriga&ccedil;&otilde;es tribut&aacute;rias que recaiam sobre os valores pagos de premia&ccedil;&atilde;o, ficando o participante respons&aacute;vel por tais recolhimentos.</p>

<p>5.5 O participante ter&aacute; direito &agrave; premia&ccedil;&atilde;o somente se o propriet&aacute;rio aderir conforme termos acima e se o im&oacute;vel indicado for publicado no site da Imobili&aacute;ria Parceira e portais parceiros.</p>

<p>5.6 N&atilde;o ter&aacute; direito &agrave; premia&ccedil;&atilde;o, indica&ccedil;&atilde;o de im&oacute;veis que tenham sido retirados da administra&ccedil;&atilde;o/ divulga&ccedil;&atilde;o das Imobili&aacute;rias Parceiras com menos de 6 meses, caso o im&oacute;vel esteja fora da administra&ccedil;&atilde;o/ divulga&ccedil;&atilde;o h&aacute; mais de 6 meses o participante far&aacute; jus &agrave; premia&ccedil;&atilde;o.</p>

<p>5.7. N&atilde;o ter&aacute; direito &agrave; premia&ccedil;&atilde;o, indica&ccedil;&atilde;o de im&oacute;veis realizadas pelo pr&oacute;prio propriet&aacute;rio do im&oacute;vel, uma vez que existe a op&ccedil;&atilde;o &ldquo;anunciar&rdquo; para estes casos espec&iacute;ficos.</p>

<p>&nbsp;</p>

<h4>6. PRIVACIDADE DA INFORMA&Ccedil;&Atilde;O</h4>

<p>6.1 Todas as informa&ccedil;&otilde;es fornecidas pelos participantes &agrave; IndiqueiApolar ser&atilde;o armazenadas em banco de dados pr&oacute;prio da empresa, sendo aplicadas medidas t&eacute;cnicas aptas a proteger os dados pessoais para as finalidades acordadas, mas a IndiqueiApolar n&atilde;o ser&aacute; respons&aacute;vel por preju&iacute;zos oriundos da utiliza&ccedil;&atilde;o de m&eacute;todos indevidos e ilegais ou fraudulentos por parte de terceiros para acessar a tais informa&ccedil;&otilde;es. A plataforma se compromete a comunicar o usu&aacute;rio em caso de alguma viola&ccedil;&atilde;o de seguran&ccedil;a dos seus dados pessoais.</p>

<p>6.2 A IndiqueiApolar poder&aacute; utilizar e ceder os dados fornecidos pelos participantes e as informa&ccedil;&otilde;es relativas ao uso da plataforma, para quaisquer finalidades, seja para an&aacute;lises, estudos, relat&oacute;rios, fins comerciais, publicit&aacute;rios, jornal&iacute;sticos entre outros tudo conforme necess&aacute;rio para o bom funcionamento ou aperfei&ccedil;oamento de suas funcionalidades.</p>

<p>6.3 Os participantes afirmam serem respons&aacute;veis pela veracidade das informa&ccedil;&otilde;es prestadas em rela&ccedil;&atilde;o a terceiros e que tem a concord&acirc;ncia destes para fazer a indica&ccedil;&atilde;o.</p>

<p>6.4 Os dados fornecidos pelo participante, poder&atilde;o ser utilizados para o envio de notifica&ccedil;&otilde;es, informa&ccedil;&otilde;es sobre a conta, avisos ou outras comunica&ccedil;&otilde;es, podendo o participante requisitar a sua exclus&atilde;o da lista de mensagem (mailing) a qualquer momento.</p>

<h4>7. DISPOSI&Ccedil;&Otilde;ES FINAIS</h4>

<p>7.1 Ficam vedadas indica&ccedil;&otilde;es de im&oacute;veis contra a vontade do propriet&aacute;rio ou de desconhecidos com o &uacute;nico intuito de receber premia&ccedil;&atilde;o.</p>

<p>7.2 O participante que realizar indica&ccedil;&atilde;o nas condi&ccedil;&otilde;es acima ser&atilde;o responsabilizados judicialmente por qualquer dano causado a IndiqueiApolar ou a terceiros.</p>

<p>7.3 Eventualmente se dois participantes indicarem o mesmo im&oacute;vel, a primeira indica&ccedil;&atilde;o que for aprovada e publicada no site e portais parceiros ser&aacute; a indica&ccedil;&atilde;o remunerada. As Imobili&aacute;rias Parceiras IndiqueiApolar n&atilde;o efetuar&atilde;o pagamentos em duplicidade.</p>

<p>7.4 N&atilde;o ser&atilde;o aceitos im&oacute;veis que porventura j&aacute; estiveram divulgados no site ou portais das Imobili&aacute;rias Parceiras IndiqueiApolar.</p>

<p>7.5 A premia&ccedil;&atilde;o paga ao participante n&atilde;o caracteriza sal&aacute;rio e n&atilde;o se vincula a qualquer direito trabalhista.</p>

<p>7.6 A campanha poder&aacute; ser encerrada a qualquer tempo e sem necessidade de aviso pr&eacute;vio ou justificativa, com o encerramento n&atilde;o haver&aacute; mais o pagamento de recompensa.</p>

<p>7.7 O participante &eacute; respons&aacute;vel por manter a confidencialidade e a seguran&ccedil;a do seu perfil na plataforma IndiqueiApolar e se compromete a n&atilde;o divulgar suas senhas de acesso a terceiros.</p>

<p>7.8 Por meio do aceite ao presente Termo, o participante reconhece que, &eacute; respons&aacute;vel por toda e qualquer atividade conduzida atrav&eacute;s de seu perfil, isentado a IndiqueiApolar e as Imobili&aacute;rias Parceira de qualquer responsabilidade por danos e preju&iacute;zos decorrentes do uso indevido da plataforma.</p>

<p>7.9 Ficam vetadas ainda, indica&ccedil;&otilde;es realizadas em forma de lista de indica&ccedil;&otilde;es em massa, captadas por rob&ocirc;s, bots ou tecnologia similar, podendo a plataforma se reservar no direito de excluir tais indica&ccedil;&otilde;es e o indicante.</p>

<p>7.10 A IndiqueiApolar e as Imobili&aacute;rias Parceiras poder&atilde;o alterar a qualquer tempo e a seu crit&eacute;rio o presente termo que passar&aacute; a vigorar assim que disponibilizado no site.</p>
`,
                },
                {
                   
                    "label": "Quando vou receber meu dinheiro extra?",
                    "text": `Assim que o imóvel for publicado, você receberá um e-mail avisando de seu 
                    crédito. O valor será pago através de depósito bancário que será creditado até o dia 
                    18 do mês seguinte da publicação`,
                }
            ]
        };
    },
    computed: {
    },
    methods: {
        toogleTagFilter(e) {
            let targetElement = jQuery(e.target);
            let toogleElm = targetElement.closest('.questions-options').find(".tag-filter-options")
            let toogleElmTagFilter = targetElement.closest('.questions-options').find(".tag-filter-indique")
            let toogleElmIcon = targetElement.closest('.questions-options').find(".dropdown-icon")

            if (toogleElmTagFilter.hasClass('selected')) {
                toogleElmTagFilter.removeClass('selected');
                toogleElmIcon.removeClass('open')
            } else {
                toogleElmTagFilter.addClass('selected')
                toogleElmIcon.addClass('open')
            }
            jQuery(toogleElm).slideToggle({
                duration: 200,
                start: function () {
                    jQuery(this).css('display', 'flex');
                }
            });
        },
    },

    mounted() {
    },

    watch: {

        districts: function (districts) {

            districts.forEach(district => {
                let exists = this.filters.district.indexOf(district)
                if (exists == -1) {
                    this.filters.district.push(district)
                }
            })

            this.filters.district.forEach((district, key) => {
                let exists = districts.indexOf(district)
                if (exists == -1) {
                    this.filters.district.splice(key, 1)
                }
            })

        },

        filters: {
            handler: function (newValue, oldValue) {
                if (this.enable_auto_search && !this.isMobile) {
                    this.applieSearch(this)
                }
            },
            deep: true
        },
        $advanced_filters: function (filters) {
            if (typeof filters['header_search'] != 'undefined' && filters['header_search']) {
                delete filters['header_search']
                this.loadExistingFilters()
            }
        }
    }

};
</script>